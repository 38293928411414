#card-container {
    width: 15rem;
    height: 25rem;
    overflow: visible;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    border-radius: 1rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.2s ease-in-out;
    user-select: none;
}

#card-image {
    top: 0rem;
    position: absolute;
    width: 16rem;
    height: 11.75rem;
    overflow: visible;
    background-color: transparent;
    z-index: 99;
    object-fit: cover;
}

#card-title {
    padding-top: 3rem;
    text-align: center;
    width: 14rem;
    height: 1rem;
    padding-bottom: .25rem;
    letter-spacing: .05rem;
    color: black;
    font-weight: bold;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: white;
}

#card-language {
    padding-top: .25rem;
    width: 14rem;
    height: 1rem;
    padding-bottom: .25rem;
    color: black;
    text-align: center;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: white;
    letter-spacing: .05rem;
}

#description {
    color: grey;
    padding-bottom: .25rem;
    padding-top: .25rem;
    width: 13rem;
    height: 7rem;
    border-radius: 0 0 1rem 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: white;
    word-spacing: .05rem;
}