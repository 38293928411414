#contact-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
}

#contact-icons-container {
    width: 40%;
    height: 30vh;
    display: flex;
    flex-wrap: wrap;
}

#contact-icon-container {
    width: 50%;
}

#copyright-container {
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
