#military-container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#military-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#military-context-container {
    z-index: 99;
    background-color: transparent;
    padding: 5%;
}

#military-context-text {
    background-color: transparent;
    font-size: 1rem;
    padding-bottom: 2%;
}

