#project-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Ensures the container is positioned correctly */
}

#slider-container {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    width: 100%;
    height: 100vh;
    scroll-snap-type: x mandatory; /* Optional: Enable snapping behavior */
    scrollbar-width: none;
}