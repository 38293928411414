#contact-container-mobile {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#contact-icons-container-mobile {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

#contact-icon-container-mobile {
    width: 100%;
    height: 40vh;
}

#copyright-container-mobile {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: black;
}

#copyright-text {
    background-color: transparent;
    word-spacing: .2rem;
}